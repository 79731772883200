/* Media Query for low resolution Tablets, iPads */
@media (min-width: 481px) and (max-width: 767px), (min-height: 481px) and (max-height: 767px) {

    .C-Layout__headerBackground{
        background-color: white;
    }
    
    .C-Layout__backgroundCover{
        background-color: white;
    }
    .imageLogo {
        height: 56px;
        width: 56px;
    }
    .imageLogo .img {
        height: 56px;
        width: 56px;
    }
    .footer {
        padding-bottom: 15px; /* Adjust padding as needed */
    }
    
    .visitTypeButton {
        width: 250px; /* Adjust width as needed */
        height: 80px; /* Adjust height as needed */
        font-size: 40px; /* Adjust font-size as needed */
    }
    
    .inputText {
        font-size: 18px; /* Adjust font-size as needed */
    }

    .kioskButton {
        width: 150px; /* Adjust width as needed */
        height: 70px; /* Adjust height as needed */
        font-size: 18px; /* Adjust font-size as needed */
        line-height: 22px; /* Adjust line-height as needed */
    }

    .datefilterbox {
        width: 90%; /* Adjust width as needed */
    }

    .datefilterbox .rectangle {
        width: 90%; /* Adjust width as needed */
    }

    .label .text {
        font-size: 18px; /* Adjust font-size as needed */
        line-height: 18px; /* Adjust line-height as needed */
    }

    .videoWrapper.qr-video video {
        height: 500px; /* Adjust height as needed */
        width: 90%; /* Adjust width as needed */
    }

    .error-icon {
        width: 100px; /* Adjust width as needed */
        height: 100px; /* Adjust height as needed */
        margin: 4em auto 1em; /* Adjust margin as needed */
    }

    .error-info {
        font-size: 22px; /* Adjust font-size as needed */
        line-height: 26px; /* Adjust line-height as needed */
    }

    .error-text {
        padding: 1em 2em; /* Adjust padding as needed */
        margin-top: 3em; /* Adjust margin-top as needed */
        font-size: 18px; /* Adjust font-size as needed */
        line-height: 22px; /* Adjust line-height as needed */
    }

    .form-enable-button {
        margin: 0.5em 0.5em; /* Adjust margin as needed */
        height: 3.5em !important; /* Adjust height as needed */
        padding: 0.5em 3em !important; /* Adjust padding as needed */
    }

    .form-container {
        width: 90%; /* Adjust width as needed */
        margin: 1.5em auto; /* Adjust margin as needed */
    }

    .form-container p {
        font-size: 18px; /* Adjust font-size as needed */
    }

    .C-Input--type-label {
        font-size: 16px; /* Adjust font-size as needed */
    }

    .welcome-target {
      font-family: Helvetica;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 125% */
      text-align: center;
    }
  
    .welcome-target-1 {
      font-family: Helvetica;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; /* 125% */
    }
  
    .welcome-button {
      width: 300px;
      height: 70px;
      text-align: center;
      font-family: 'Helvetica for Target', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px; /* 125% */
    }
  
    /* Responsive Styles for welcome-button */

    .welcome-IntroText {
      font-family: 'Helvetica', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 125% */
    }
  
    /* Responsive Styles for welcome-IntroText */
    .welcome-IntroText {
      @media only screen and (max-width: 600px) {
        font-size: 20px; /* Adjust font size for smaller screens */
      }
    }
  
    .Loadcontainer {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 99;
      display: flex;
      justify-content: center; /* Horizontal centering */
      align-items: center; /* Vertical centering */
    }
  
    .progressContainer {
      width: 100px;
      height: 100px;
      padding: 30px;
      background-color: rgba(0, 0, 0, 0.5);
    }
  
    .reception-text {
      font-family: Helvetica for Target;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      align-content: left;
    }
  
    .reception-button {
      width: 710px;
      height: 112px;
      justify-content: flex-start;
    }
  
    .reception-text {
      font-family: Helvetica;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
    }
  
    .reception-cards-item {
      padding: 5px;
      margin: 10px;
    }
  
    .reception-sub-text {
      font-family: Helvetica;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  
    .reception-create-button {
      width: 330px;
      height: 64px;
      font-family: Helvetica;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
    }
  
    .select-type {
      font-family: Helvetica;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px; /* 125% */
    }
  
    .select-type-but {
      font-family: Helvetica;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px; /* 125% */
    }
  
    .tab-content {
      flex-direction: column;
    }
  
    .date-button {
      position: relative;
    }
  
    .dot {
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      width: 8px;
      height: 8px;
      background-color: blue;
      border-radius: 50%;
    }
    .render-field-common {
      width: 310px;
      height: 80px;
    }

    @media only screen and (orientation: landscape) {
      .render-field-common {
        width: 500px; /* Adjust width for landscape orientation */
        height: 80px; /* Adjust height for landscape orientation */
      }
      .C-Input.isDisabled {
          height: 50px;
      }
      .C-Input {
          height: 50px;
      }
    }
  
    /* Responsive Styles for header-action */
    .header-action {
      color: #3d70d6;
      padding-left: 5px;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  
    .header-help-action {
      color: #3d70d6;
    }
  
    .responsive-button-container {
      position: relative;
      bottom: 20px;
      right: 10px;
    }
  
    .adhocButton {
      width: 150px;
      height: 50px;
      text-align: center;
      font-family: 'Helvetica for Target', sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin: 12px;
    }
  
    .field-button {
      position: relative;
      background-color: #fff;
      color: #333;
      border: 1px solid #ccc;
      padding: 10px 40px 10px 20px; /* Adjust padding to accommodate the icon */
      font-size: 16px;
      cursor: pointer;
      display: inline-block;
      text-align: center;
      text-decoration: none;
      border-radius: 4px;
      overflow: hidden; /* Hide overflow to prevent icon from sticking out */
    }
  
    .field-button:hover {
      background-color: #f0f0f0;
    }
  
    /* Icon styles */
    .icon {
      position: absolute;
      top: 50%;
      right: 10px; /* Adjust the right spacing for the icon */
      transform: translateY(-50%);
    }
    /* Styles for low-resolution tablets and iPads in both portrait and landscape orientations */



      .options-container {
        max-height: 200px; /* Set the maximum height for the container */
        overflow-y: auto; /* Enable vertical scrolling */
      }

      .selected {
        background-color: #3d70d6; /* Change color as needed */
      }

      .scrollable-grid-container {
        max-height: 700px; /* Adjust the height as needed */
        overflow-y: auto; /* Enable vertical scrollbar when content overflows */
        margin: 12px;
      }

}
